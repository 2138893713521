
import Vue from 'vue';
import { mdiHome } from '@mdi/js';

export default Vue.extend({
  name: 'App',

  data: () => ({
    appTitle: 'SERICE',
    sidebar: false,
    menuItems: [
        { title: 'Home', path: '/', icon: mdiHome },
        { title: 'Site Characterization', pages: [
          { title: 'MASWaves', path: '/maswaves' },
          { title: 'Velocity Profiles', path: '/velocity-profiles' },
        ]},
        { title: 'Ground Motion', path: '/ground-motion'},
        { title: 'Vulnerability', path: '/vulnerability'},
        { title: 'Publications', path: '/publications'},
    ],
  }),
});
