import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
     // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    path: '/maswaves',
    name: 'MASWaves',
    component: () => import(/* webpackChunkName: "maswaves" */ '../views/MASWaves.vue'),
  },
  {
    path: '/velocity-profiles',
    name: 'Velocity Profiles',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/VelocityProfiles.vue'),
  },
  {
    path: '/time-histories',
    name: 'Time Histories',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/TimeHistories.vue'),
  },
  {
    path: '/ground-motion',
    name: 'Ground Motion',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/GroundMotion.vue'),
  },
  {
    path: '/vulnerability',
    name: 'Vulnerability',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/Vulnerability.vue'),
  },
  {
    path: '/vulnerability-functions',
    name: 'Vulnerability Functions',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/VulnerabilityFunctions.vue'),
  },
  {
    path: '/fragility-functions',
    name: 'Fragility Functions',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/FragilityFunctions.vue'),
  },
  {
    path: '/routines',
    name: 'Routines',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/Routines.vue'),
  },
  {
    path: '/publications',
    name: 'Publications',
    component: () => import(/* webpackChunkName: "publications" */ '../views/Publications.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
